import { useContext } from "react";
import { Document, Page } from "react-pdf";
import TradingPlan from "./plan.pdf";
import ThemeContext from "../utils/theme-context";
import useWindowDimensions from "../utils/width";
require("pdfjs-dist/build/pdf.worker.entry.js");

const Plan = () => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  // const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        margin: "auto",
      }}
    >
      <Document file={TradingPlan} loading="">
        <Page
          loading=""
          width={width < 500 ? 430 : 980}
          pageNumber={1}
          scale={1}
          // scale={width >= 1470 ? 1.5 : 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </div>
  );
};

export default Plan;
