import styled from "styled-components";
import { Button, FormControlLabel, TextField } from "@mui/material";

import { styled as Style } from "@mui/material/styles";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  over-flow-y: hidden;
  position: fixed;
`;

export const Wallpaper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url(./login.jpeg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
`;

export const InputContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainerInner = styled.div`
margin-top: 10%;
  width: 400px;
  height: 400px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 0px;
`;

export const ErrorMessage = styled.h6`
  color: red;
  font-weight: 200;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.h5};
  margin-top: -20px;
`;

export const Title = styled.h1`
  color: #fcfcff;
  font-weight: 200;
  letter-spacing: 3px;
  border-bottom: 1px solid #fcfcff;
  padding: 0 0 10px 0;
  font-size: ${(props) => props.theme.fontSizes.h3};
`;

export const FormControl = styled(FormControlLabel)``;

export const UserName = Style(TextField)({
  input: {
    "&:-webkit-autofill": {
      // opacity: '0.5',
      WebkitBoxShadow: "0 0 0 100px #FCFCFF inset",
      WebkittextFillColor: "#FCFCFF",
    },
    margin: "1em auto 0 auto",
    height: "10px",
    maxWidth: "250px",
    padding: "1em",
    color: "#FCFCFF",
    borderRadius: "10px",
    border: "1px solid #FCFCFF",
    "&::placeholder": {
      color: "#FCFCFF",
    },
  },
});

export const Password = Style(TextField)({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #FCFCFF inset",
      WebkittextFillColor: "#FCFCFF",
    },
    margin: "-2em auto 0 auto",
    height: "10px",
    maxWidth: "250px",
    padding: "1em",
    color: "#FCFCFF",
    borderRadius: "10px",
    border: "1px solid #FCFCFF",
    "&::placeholder": {
      color: "#FCFCFF",
    },
  },
});

export const LogInBtn = Style(Button)({
  margin: "-5em auto 0 auto",
  color: "#FCFCFF",
  border: "1px solid #FCFCFF",
  height: "37px",
  width: "50%",
  borderRadius: "10px",
});
