import { React, useContext, useState } from "react";
import ThemeContext from "../../utils/theme-context";
import AuthContext from "../../utils/auth-context";
import useWindowDimensions from "../../utils/width";
import Tooltip from "@mui/material/Tooltip";

const StatsBar = ({
  realisedPercentage,
  realisedPercentageBacktest,
  calculateFields,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const { width } = useWindowDimensions();
  /* eslint-disable no-unused-vars */
  const [storage, setStorage] = useState("");
  const [run, setRun] = useState(false);

  const setRotater = async () => {
    const storage = localStorage.getItem("rotater");
    if (storage === "Start Of Year") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "3 Months"
      );
      localStorage.setItem("rotater", "3 Months");
      // setStorage("3 Months");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "3 Months") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Monthly"
      );
      localStorage.setItem("rotater", "Monthly");
      // setStorage("Monthly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Monthly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Weekly"
      );
      localStorage.setItem("rotater", "Weekly");
      // setStorage("Weekly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Weekly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "24 Hours"
      );
      localStorage.setItem("rotater", "24 Hours");
      // setStorage("24 Hours");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "24 Hours") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "All Time"
      );
      localStorage.setItem("rotater", "All Time");
      // setStorage("All Time");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Start Of Year"
      );
      localStorage.setItem("rotater", "Start Of Year");
      // setStorage("Start Of Year");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    }
  };

  return (
    <Tooltip title={localStorage.getItem("rotater")} placement="right">
      <div
        onClick={() => {
          if (!run) {
            setRun(true);
            setRotater();
          }
        }}
        style={{
          cursor: "pointer",
          marginRight:
            auth.backtestingMode && width > 1049
              ? "18%"
              : !auth.backtestingMode && width > 1049
              ? "20%"
              : auth.backtestingMode && width < 1049
              ? "5%"
              : !auth.backtestingMode && width < 1049
              ? "5%"
              : "0%",
          position: width <= 500 ? "absolute" : null,
          top: "70px",
          left: "190px",
        }}
      >
        {auth.backtestingMode ? (
          <>
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                border:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : " 1px solid black",

                fontWeight: "300",
                fontSize: "0.68em",
                borderRadius: "10px",
                padding: "8px 15px",
              }}
            >
              <span
                style={{
                  color:
                    realisedPercentageBacktest < 0
                      ? "#f23645"
                      : realisedPercentageBacktest > 0
                      ? "#26a65d"
                      : realisedPercentageBacktest === 0
                      ? "#ED9E0E"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(realisedPercentageBacktest)}% {storage}
              </span>
            </p>
          </>
        ) : (
          <>
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                border:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid black",

                fontWeight: "300",
                fontSize: "0.68em",
                borderRadius: "10px",
                padding: "8px 15px",
                textAlign: "center",
                width: "80px",
              }}
            >
              <span
                style={{
                  color:
                    realisedPercentage < 0
                      ? "#f23645"
                      : realisedPercentage > 0
                      ? "#26a65d"
                      : realisedPercentage === 0
                      ? "#ED9E0E"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(realisedPercentage)}% {storage && storage}
              </span>{" "}
            </p>
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default StatsBar;
