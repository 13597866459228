import { useEffect, useState } from "react";
import {
  Wallpaper,
  Wrapper,
  LogInBtn,
  InputContainer,
  InputContainerInner,
  UserName,
  Password,
} from "./log-in.styles";
// import useWindowDimensions from "../utils/width";
import { useNavigate } from "react-router-dom";

const LogIn = ({ handleLogIn }) => {
  // const { width } = useWindowDimensions();
  const navigate = useNavigate();

  // const url = window.location.href;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const email =
  //   url === "http://localhost:3000/log-in"
  //     ? process.env.REACT_APP_EMAIL
  //     : url === "https://trading-center-bw3nk.ondigitalocean.app/log-in"
  //     ? process.env.REACT_APP_EMAIL
  //     : "";

  // const password =
  //   url === "http://localhost:3000/log-in"
  //     ? process.env.REACT_APP_PASSWORD
  //     : url === "https://trading-center-bw3nk.ondigitalocean.app/log-in"
  //     ? process.env.REACT_APP_PASSWORD
  //     : "";

  const handleInputs = (value) => {
    switch (value.target.name) {
      case "email":
        setEmail(value.target.value);
        break;
      case "password":
        setPassword(value.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("TC_TOKEN_AUTH") && localStorage.getItem("user")) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Wallpaper />
      <form style={{ height: "50%", width: "100%", margin: "0 auto" }}>
        <InputContainer>
          {/* <Title>Trading Centre</Title> */}

          <InputContainerInner>
            <UserName
              sx={{
                "& fieldset": { border: "none" },
              }}
              name="email"
              value={email}
              onChange={handleInputs}
              variant="outlined"
              type="email"
              placeholder="Email"
              id="username"
              inputProps={{
                autoComplete: "username",
              }}
            />
            <Password
              sx={{
                "& fieldset": { border: "none" },
              }}
              id="password"
              name="password"
              onChange={handleInputs}
              value={password}
              variant="outlined"
              type="password"
              placeholder="Password"
              inputProps={{
                autoComplete: "current-password",
              }}
            />

            <LogInBtn
              onClick={() => {
                handleLogIn(email, password);
                // setTimeout(() => {
                //   document.body.requestFullscreen();
                // }, 400);
              }}
            >
              Log In
            </LogInBtn>
          </InputContainerInner>
      
        </InputContainer>
      </form>
    </Wrapper>
  );
};

export default LogIn;

/* <div
style={{
  fontSize: "16px",
  color: "#FCFCFF",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
}}
>
<p>Mobile Version Coming Soon....</p>
</div> */
